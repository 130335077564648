import React, { useRef } from "react";
import Layout from "../components/Layout";
import Modal from "../components/Modal";
// import Gift from "../components/Gift";
// import { Link } from "gatsby";
// import Repubblica from "../components/Repubblica";
// import Frutta from "../components/Frutta";
// import Verdura from "../components/Verdura";
// import Mix from "../components/Mix";
import Form from "../components/Form";
// import Rating from "../components/Rating";
// import Infographic from "../components/Infographic";
// import Trustpilot from "../components/Trustpilot";
// import RecensioneIsabella from "../components/RecensioneIsabella";
// import Accordion from "../components/Accordion";
import "../css/scroller.css";
// import RecensioneSara from "../components/RecensioneSara";
// import RecensioneSimona from "../components/RecensioneSimona";
// import Arance from "../components/Arance";
// import Limoni from "../components/Limoni";
// import ReactPlayer from "react-player/file";

export default () => {
    const modalRef1 = useRef();

    return (
        <Layout>
            <main className="flex-grow flex flex-col justify-evenly m-auto py-2 px-4 md:px-32">
                <section className="flex flex-wrap flex-col-reverse md:flex-row items-center mt-6">
                    <div className="flex flex-col justify-between mx-auto md:ml-0 md:mr-auto max-w-xl">
                        <h1 className="px-4 pt-5 text-4xl md:text-5xl md:text-justify text-center text-black font-serif tracking-wide">
                            Un'utopia agricola
                        </h1>
                        <p className="px-4 mt-2 text-xl text-justify text-gray-800 font-sans">
                            <span className="font-black">
                                Dimora Delle Balze
                            </span>{" "}
                            sorge su 27 ettari di terreni{" "}
                            <span className="font-black">
                                biologici certificati
                            </span>{" "}
                            che anticamente presero il nome di "Passu Latru" a
                            causa dei numerosi briganti che li attraversavano.
                            <br />
                            <br />
                            E’ da questa terra che nasce il progetto{" "}
                            <span className="font-black">Passo Ladro</span>: una
                            realtà <span className="font-black">biologica</span>{" "}
                            e <span className="font-black">innovativa</span> che
                            coltiva in modo{" "}
                            <span className="font-black">etico</span> e{" "}
                            <span className="font-black">sostenibile</span>{" "}
                            frutta e verdura{" "}
                            <span className="font-black">biologica</span> e{" "}
                            <span className="font-black">di stagione</span>.
                        </p>
                        <div className="px-4 mt-8 md:text-justify text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <button className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent">
                                <a href="https://passoladro.it/">
                                    <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                        Scopri di più
                                    </h2>
                                </a>
                            </button>
                            {/* </div> */}
                        </div>

                        {/* <p className="px-4 mt-8 text-xl text-gray-800 font-sans">
                            Parlano di noi:
                        </p>
                        <div className="px-4 mt-2 text-xl text-gray-800 font-sans text-center md:text-justify">
                            <a
                                href="https://www.ilgusto.it/2021/04/22/news/sicilia_agricoltura_azienda_passo_ladro_pino_lops-297381732/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Repubblica />
                            </a>
                        </div> */}

                        <Modal ref={modalRef1}>
                            <Form />
                        </Modal>
                    </div>

                    <div
                        className="mx-auto md:mr-0 md:ml-auto w-full max-w-5xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <video
                            controls
                            autoPlay
                            muted
                            playsInline
                            loop
                            height="720"
                            width="1280"
                        >
                            {/* <source
                                src="./videos/video_cassette.webm"
                                type="video/webm"
                            /> */}
                            <source
                                src="./videos/video_campi.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </section>
                <section className="flex flex-wrap flex-col md:flex-row items-center mt-20">
                    <div
                        className="mx-auto md:ml-0 md:mr-auto w-full max-w-5xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <video
                            controls
                            muted
                            // autoPlay
                            playsInline
                            loop
                            height="720"
                            width="1280"
                        >
                            {/* <source
                                src="./videos/video_cassette.webm"
                                type="video/webm"
                            /> */}
                            <source
                                src="./videos/clip_caponata_final.mp4"
                                type="video/mp4"
                            />
                            {/* https://vimeo.com/500950123 */}
                        </video>
                    </div>
                    <div className="flex flex-col justify-between mx-auto md:mr-0 md:ml-auto max-w-xl">
                        <h1 className="px-4 pt-5 text-4xl md:text-5xl md:text-justify text-center text-black font-serif tracking-wide">
                            Il futuro della tradizione
                        </h1>
                        <p className="px-4 mt-2 text-xl text-justify text-gray-800 font-sans">
                            Nel 2020 viene creata una{" "}
                            <span className="font-black">
                                cucina gastronomica
                            </span>{" "}
                            all’inizio del viale che porta a Dimora delle Balze
                            dove vengono trasformate le materie prime da{" "}
                            <span className="font-black">
                                esperti chef e tecnologi
                            </span>{" "}
                            del settore.
                            <br />
                            <br />
                            Il mix tra{" "}
                            <span className="font-black">
                                professionalità
                            </span>,{" "}
                            <span className="font-black">sostenibilità</span> e{" "}
                            <span className="font-black">tecnologia</span> vi
                            permetterà di degustare un’eccellente{" "}
                            <span className="font-black">prodotto gourmet</span>{" "}
                            , attento al vostro{" "}
                            <span className="font-black">benessere</span> e
                            amico del vostro{" "}
                            <span className="font-black">palato</span>.
                        </p>

                        <div className="px-4 mt-8 md:text-justify text-center">
                            {/* <div className="max-w-4xl mx-auto"> */}
                            <button className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent">
                                <a href="https://passoladro.it/">
                                    <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                        Scopri di più
                                    </h2>
                                </a>
                            </button>
                            {/* </div> */}
                        </div>

                        {/* <p className="px-4 mt-8 text-xl text-gray-800 font-sans">
                            Parlano di noi:
                        </p>
                        <div className="px-4 mt-2 text-xl text-gray-800 font-sans text-center md:text-justify">
                            <a
                                href="https://www.ilgusto.it/2021/04/22/news/sicilia_agricoltura_azienda_passo_ladro_pino_lops-297381732/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Repubblica />
                            </a>
                        </div> */}

                        <Modal ref={modalRef1}>
                            <Form />
                        </Modal>
                    </div>
                </section>
            </main>
        </Layout>
    );
};
